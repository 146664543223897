<template>

  <div
    :class="{ 'opacity-0': !showItems }"
  >
    <div class="w-full my-5 text-2xl text-center text-primary uppercase">
      Preguntas frecuentes
    </div>
    <div
      class="Accordion"
    >
      <div class="body">

        <template v-if="isMobileView">

          <expander
            v-for="(question, index) in questionList"
            :key="'q-mobile' + index"
            :title="question.title"
            :height="
              getScaleImgValues(maxWidth, question.width_xs, question.height_xs)
                .h
            "
          >
            <img
              :src="question.imagen_xs"
              :width="
                getScaleImgValues(
                  maxWidth,
                  question.width_xs,
                  question.height_xs
                ).w
              "
              :height="
                getScaleImgValues(
                  maxWidth,
                  question.width_xs,
                  question.height_xs
                ).h
              "
              class="w-full"
            />
          </expander>
        </template>

        <template v-else>
          <expander
            v-for="(question, index) in questionList"
            :key="'q-' + index"
            :title="question.title"
            :height="
              getScaleImgValues(maxWidth, question.width_xl, question.height_xl)
                .h
            "
          >
            <img
              :src="question.imagen_xl"
              :width="
                getScaleImgValues(
                  maxWidth,
                  question.width_xl,
                  question.height_xl
                ).w
              "
              :height="
                getScaleImgValues(
                  maxWidth,
                  question.width_xl,
                  question.height_xl
                ).h
              "
              class="w-full"
            />
          </expander>
        </template>

      </div>

    </div>
  </div>

</template>

<script>
import Expander from "../components/Acordion/Accordion.vue";
export default {
  name: "QuestionsPage",
  data: function () {
    return {
      questionList: [],
      maxWidth: 0,
      isMobileView: null,
      showItems: false,
    };
  },
  components: {
    Expander,
  },
  computed: {},
  mounted() {
    let self = this;
    this.$store.commit("updateBgApp", "assets/img/questions_background.jpg");
    // console.log(this.$APIServices.frecuent_questions);
    this.$http
    .get(this.$APIEndpoint + this.$APIServices.frecuent_questions)
      .then(() => {
        // console.log(response)
        self.questionList = [

    {
        "id": 1,
        "title": "Accidentes en Viaje",
        "imagen_xl": require("./../assets/img/frequentQuestions/Web/Seguro_Viajero.png"),
        "width_xl": "1200.00",
        "height_xl": "850.00",
        "imagen_xs": require("./../assets/img/frequentQuestions/Movil/Movil_FAQ_Seguro-Viajero.png"),        
        "width_xs": "1200.00",
        "height_xs": "748.00"
    },
    {
        "id": 2,
        "title": "Accidentes personales",
        "imagen_xl": require("./../assets/img/frequentQuestions/Web/Accidentes_Personales.png"),
        "width_xl": "1200.00",
        "height_xl": "863.00",
        "imagen_xs": require("./../assets/img/frequentQuestions/Movil/Movil_FAQ_Accidentes_Personales.png"),
        "width_xs": "1200.00",
        "height_xs": "1050.00"
    },
    {
        "id": 3,
        "title": "Asistencia Medica",
        "imagen_xl": require("./../assets/img/frequentQuestions/Web/VRIM.png"),
        "width_xl": "1200.00",
        "height_xl": "777.00",
        "imagen_xs": require("./../assets/img/frequentQuestions/Movil/Movil_FAQ_VRIM.png"),
        "width_xs": "1200.00",
        "height_xs": "777.00"
    },
    {
        "id": 4,
        "title": "Bicicleta",
        "imagen_xl": require("./../assets/img/frequentQuestions/Web/Bicicletas.png"),
        "width_xl": "1200.00",
        "height_xl": "440.00",
        "imagen_xs": require("./../assets/img/frequentQuestions/Movil/Movil_FAQ_Bicicletas.png"),
        "width_xs": "1200.00",
        "height_xs": "440.00"
    },
    {
        "id": 5,
        "title": "Cáncer",
        "imagen_xl": require("./../assets/img/frequentQuestions/Web/Cancer.png"),
        "width_xl": "1200.00",
        "height_xl": "1073.00",
        "imagen_xs": require("./../assets/img/frequentQuestions/Movil/Movil_FAQ_Cancer.png"),
        "width_xs": "1200.00",
        "height_xs": "1073.00"
    },
    {
        "id": 6,
        "title": "Covid",
        "imagen_xl": require("./../assets/img/frequentQuestions/Web/COVID_Quirúrgico.png"),
        "width_xl": "1200.00",
        "height_xl": "430.00",
        "imagen_xs": require("./../assets/img/frequentQuestions/Movil/Movil_FAQ_COVID_Quirúrgico.png"),
        "width_xs": "1200.00",
        "height_xs": "430.00"
    },
    {
        "id": 7,
        "title": "Dental",
        "imagen_xl": require("./../assets/img/frequentQuestions/Web/Dental.png"),
        "width_xl": "1200.00",
        "height_xl": "1018.00",
        "imagen_xs": require("./../assets/img/frequentQuestions/Movil/Movil_FAQ_Dental.png"),
        "width_xs": "1200.00",
        "height_xs": "1018.00"
    },
    {
        "id": 8,
        "title": "Funerario",
        "imagen_xl": require("./../assets/img/frequentQuestions/Web/Funerarios.png"),
        "width_xl": "1200.00",
        "height_xl": "1293.00",
        "imagen_xs": require("./../assets/img/frequentQuestions/Movil/Movil_FAQ_Funerarios.png"),
        "width_xs": "1200.00",
        "height_xs": "1293.00"
    },
    {
        "id": 9,
        "title": "Gastos Médicos",
        "imagen_xl": require("./../assets/img/frequentQuestions/Web/Gastos_Médicos_Mayores.png"),
        "width_xl": "1200.00",
        "height_xl": "440.00",
        "imagen_xs": require("./../assets/img/frequentQuestions/Movil/Movil_FAQ_Gastos_Médicos_Mayores.png"),
        "width_xs": "1200.00",
        "height_xs": "440.00"
    },
    {
        "id": 10,
        "title": "Hogar",
        "imagen_xl": require("./../assets/img/frequentQuestions/Web/Hogar.png"),
        "width_xl": "1200.00",
        "height_xl": "1684.00",
        "imagen_xs": require("./../assets/img/frequentQuestions/Movil/Movil_FAQ_Hogar.png"),
        "width_xs": "1200.00",
        "height_xs": "1684.00"
    },
    {
        "id": 11,
        "title": "Mascotas",
        "imagen_xl": require("./../assets/img/frequentQuestions/Web/Mascotas.png"),
        "width_xl": "1200.00",
        "height_xl": "1073.00",
        "imagen_xs": require("./../assets/img/frequentQuestions/Movil/Movil_FAQ_Mascotas.png"),
        "width_xs": "1200.00",
        "height_xs": "1073.00"
    },
    {
        "id": 12,
        "title": "Preguntas Frecuentes",
        "imagen_xl": require("./../assets/img/frequentQuestions/Web/Preguntas_Frecuentes.png"),
        "width_xl": "1200.00",
        "height_xl": "856.00",
        "imagen_xs": require("./../assets/img/frequentQuestions/Movil/Movil_FAQ_Preguntas_Frecuentes.png"),
        "width_xs": "1200.00",
        "height_xs": "856.00"
    },
    {
        "id": 13,
        "title": "Pyme",
        "imagen_xl": require("./../assets/img/frequentQuestions/Web/Pyme.png"),
        "width_xl": "1200.00",
        "height_xl": "1393.00",
        "imagen_xs": require("./../assets/img/frequentQuestions/Movil/Movil_FAQ_Pyme.png"),
        "width_xs": "1200.00",
        "height_xs": "1393.00"
    },
    {
        "id": 14,
        "title": "Quirúrgico",
        "imagen_xl": require("./../assets/img/frequentQuestions/Web/COVID_Quirúrgico.png"),
        "width_xl": "1200.00",
        "height_xl": "430.00",
        "imagen_xs": require("./../assets/img/frequentQuestions/Movil/Movil_FAQ_COVID_Quirúrgico.png"),
        "width_xs": "1200.00",
        "height_xs": "430.00"
    },

]











        setTimeout(() => {
          if (document.documentElement.clientWidth < 640) {
            // Hay componentes que se deben renderizar solo en la versión web
            self.isMobileView = true;
          } else {
            self.isMobileView = false;
          }
          self.maxWidth = document.querySelector(".Expander").clientWidth;
          self.startInitAnimation();
        }, 500);
      });
  },
  destroyed() {},
  methods: {
    startInitAnimation() {
      let delayBetweenItems = 200;
      this.showItems = true;
      this.$anime.timeline().add({
        targets: ".Expander",
        translateX: (el, i) => {
          if (!(i % 2)) {
            return [-500, 0];
          } else {
            return [500, 0];
          }
        },
        opacity: [0, 1],
        easing: "easeOutExpo",
        duration: 1000,
        delay: (el, i) => delayBetweenItems * (i + 1),
      });
    },
    getScaleImgValues(widthMax, orig_w, orig_h) {
      orig_w = Number(orig_w);
      orig_h = Number(orig_h);
      /* Recalcula los valores de alto y ancho de una imagen para ajustarlos al contenedor */
      // console.log(widthMax, orig_w, orig_h, orig_w / widthMax);
      let divider_factor = orig_w / widthMax;
      return {
        w: orig_w / divider_factor,
        h: orig_h / divider_factor,
      };
    },
  },
};
</script>
<style>

  .Accordion{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .Accordion .body{
    width: 100%;
    padding: 0rem 10%;
  }

</style>
