var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{ 'opacity-0': !_vm.showItems }},[_c('div',{staticClass:"w-full my-5 text-2xl text-center text-primary uppercase"},[_vm._v("\n    Preguntas frecuentes\n  ")]),_vm._v(" "),_c('div',{staticClass:"Accordion"},[_c('div',{staticClass:"body"},[(_vm.isMobileView)?_vm._l((_vm.questionList),function(question,index){return _c('expander',{key:'q-mobile' + index,attrs:{"title":question.title,"height":_vm.getScaleImgValues(_vm.maxWidth, question.width_xs, question.height_xs)
              .h}},[_c('img',{staticClass:"w-full",attrs:{"src":question.imagen_xs,"width":_vm.getScaleImgValues(
                _vm.maxWidth,
                question.width_xs,
                question.height_xs
              ).w,"height":_vm.getScaleImgValues(
                _vm.maxWidth,
                question.width_xs,
                question.height_xs
              ).h}})])}):_vm._l((_vm.questionList),function(question,index){return _c('expander',{key:'q-' + index,attrs:{"title":question.title,"height":_vm.getScaleImgValues(_vm.maxWidth, question.width_xl, question.height_xl)
              .h}},[_c('img',{staticClass:"w-full",attrs:{"src":question.imagen_xl,"width":_vm.getScaleImgValues(
                _vm.maxWidth,
                question.width_xl,
                question.height_xl
              ).w,"height":_vm.getScaleImgValues(
                _vm.maxWidth,
                question.width_xl,
                question.height_xl
              ).h}})])})],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }